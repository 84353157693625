import { useStaticQuery, graphql } from 'gatsby';

/**
 * Retrieves all categories from WordPress
 * Removes Uncategorised
 *
 * @returns
 */
const useCategoriesData = () => {
    const categoriesData = useStaticQuery(graphql`
        query categories {
            wordpress {
                productCategories(first: 50, where: {hideEmpty: true}) {
                    nodes {
                        name
                        slug
                        language {
                            code
                        }
                        ACFProductCategoryFields {
                            shortTitle
                        }
                    }
                }
            }
        }
    `);

    const categories = categoriesData.wordpress.productCategories.nodes.filter(category => category.name !== 'Uncategorised');

    return categories;
};

export default useCategoriesData;
