import React from 'react';
import { graphql } from 'gatsby';
import 'array-flat-polyfill';

import useCategoriesData from '../hooks/hook.useCategoriesData';

const resources = require('../locales/translations.json');

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import SectionNav from '../components/component.SectionNav';
import HeroBanner from '../components/hero-banner/component.banner';
import ProductsList from '../components/plp/component.products-list';
import Dropdown from '../components/component.dropdown';
import CTABanner from '../components/component.cta-banner';
import Info from '../components/component.info';
import CookieNotice from "../components/component.cookie-notice";

/**
 * Returns an array of filters, created from the supplied product data, 
 * this means we know that filters are only present where prodcuts exist.
 * Uses the information supplied to form the link to the relevant page inline
 * with the structure created in gatsby-node.
 * 
 * This method is used rather than just filtering the products array directly so 
 * there are landing pages for SEO.
 *
 * @param {*} { products, type }
 * @returns
 */
function getSectionFilter({ products, type, slug }) {
    const filters = [];
    let orderedFilters = [];

    products.map(product => {
        product[type].nodes.map(item => {
            const existingFilter = filters.filter(filter => filter.id === item.id);

            if (!existingFilter.length) {
                item.path = `/${slug}/${item.slug}/`;
                filters.push({
                    ...item
                });
            }
        });
    });

    // Order filter
    if (type === 'classes') {
        orderedFilters = orderClass(filters);
    } else if (type === 'levels') {
        orderedFilters = orderLevel(filters);
    } else {
        orderedFilters = [...filters];

        // Order alphabetically
        orderedFilters.sort((a, b) => a.name.localeCompare(b.name));
    }

    // If there are filters present for this section 
    // add see all to the start pointing to the parent category
    if (orderedFilters.length > 0) {
        orderedFilters.unshift({
            name: "See all",
            path: `/${slug}/`,
            id: 'seeAll',
        });
    }

    return orderedFilters;
}

/**
 * Orders classes to predefined order
 *
 * @param {*} list
 * @returns
 */
function orderClass(list) {
    const orderedList = [];
    
    // Child array - Added last for any items where the order is not defined
    orderedList[4] = [];
    
    list.map(item => {
        // Add items to the array within child arrays in the required order
        switch (item.name) {
            case 'Single':
                orderedList[0] = [item];
                break;
            case 'Pair/Double':
                orderedList[1] = [item];
                break;
            case 'Four/Quad':
                orderedList[2] = [item];
                break;
            case 'Eight/Octuple':
                orderedList[3] = [item];
                break;
            default:
                orderedList[4].push(item);
          }
    });

    // Returns the flattened array in the specified order
    return orderedList.flat();
}

/**
 * Orders lists to predefined order
 *
 * @returns
 */
function orderLevel(list) {
    const orderedList = [];
    
    // Child array - Added last for any items where the order is not defined
    orderedList[4] = [];
    
    list.map(item => {
        // Add items to the array within child arrays in the required order
        switch (item.name) {
            case 'Novice':
                orderedList[0] = [item];
                break;
            case 'Intermediate competitive':
                orderedList[1] = [item];
                break;
            case 'Advanced competitive': 
                orderedList[2] = [item];
                break;
            case 'Elite Competitive':
                orderedList[3] = [item];
                break;
            default:
                orderedList[4].push(item);
          }
    });

    // Returns the flattened array in the specified order
    return orderedList.flat();
}

/**
 * Returns the most relvant title by importance from the title data supplied.
 *
 * Importance depdent on the page loaded:
 * 
 *  - Filtered page (e.g. /racing-boats/single/) - Where a specific title is supplied
 *      -- Loads the relevant title to the category and filter (e.g. Racing Boat > Single > Title)
 *  - Filtered page (e.g. /racing-boats/single/) - Where no specific title is supplied 
 *      -- Generates a title from the category name - filter name
 * 
 *  - Category - No filter level (e.g. /racing-boats/)
 *      -- Generates the title from the category name
 * 
 *  - Fallback where no title present
 * 
 * @param {*} [{ filterData, filterName, name }={}]
 * @returns
 */
function getTitle({ filterData, filterName, name } = {}) {
    let title = `WinTech Racing`;

    if (filterData && filterData.title) {
        title = filterData.title;
    } else if (name && filterName) {
        title = `${name} - ${filterName}`
    } else if (name) {
        title = `${name}`;
    }

    return title;
}

/**
 * Returns the most relvant description by importance from the description data supplied. 
 * 
 * Importance depdent on the page loaded:
 * 
 *  - Filtered page (e.g. /racing-boats/single/) - Where a specific description is supplied
 *      -- Loads the relevant description to the category and filter (e.g. Racing Boat > Single > Description)
 *  - Filtered page (e.g. /racing-boats/single/) - Where no specific description is supplied 
 *      -- Gets the description from the overall filter (e.g. Single > Description)
 *  - Filtered page (e.g. /racing-boats/single/) - Where no overall filter description is supplied
 *      -- Gets the description from the category (e.g. Racing Boat > Description)
 * 
 *  - Category - No filter level (e.g. /racing-boats/)
 *      -- Gets the description from the category (e.g. Racing Boat > Description)
 * 
 *  - Fallback where no description present
 *
 * @param {*} [{ filterData, filterOverallData, categoryData }={}]
 * @returns
 */

function getDescription({ filterData, filterOverallData, categoryData } = {}) {
    let description = 'WINTECH Racing offers a complete line-up of shells ranging from singles to eights, training to competitive, entry-level to the best money can buy. Build your fleet WINTECH Racing.';
    
    if (filterData && filterData.description) {
        description = filterData.description;
    } else if (filterOverallData && filterOverallData.description) {
        description = filterOverallData.description;
    } else if (categoryData && categoryData.description) {
        description = categoryData.description;
    }

    return description;
}

/**
 * Returns the most relvant subtitle by importance from the subtitle data supplied. 
 * 
 * Importance depdent on the page loaded:
 * 
 *  - Filtered page (e.g. /racing-boats/single/) - Where a specific subtitle is supplied
 *      -- Loads the relevant subtitle to the category and filter (e.g. Racing Boat > Single > Subtitle)
 *  - Filtered page (e.g. /racing-boats/single/) - Where no specific subtitle is supplied 
 *      -- Gets the subtitle from the overall filter (e.g. Single > Subtitle)
 *  - Filtered page (e.g. /racing-boats/single/) - Where no overall filter subtitle is supplied
 *      -- Gets the subtitle from the category (e.g. Racing Boat > Subtitle)
 * 
 *  - Category - No filter level (e.g. /racing-boats/)
 *      -- Gets the subtitle from the category (e.g. Racing Boat > Subtitle)
 * 
 *  - Fallback where no subtitle present
 *
 * @param {*} [{ filterData, filterOverallData, categoryData }={}]
 * @returns
 */
function getSubtitle({ filterData, filterOverallData, categoryData } = {}) {
    let subtitle = 'Proven performance. Unparalleled value.';

    if (filterData && filterData.subtitle) {
        subtitle = filterData.subtitle;
    } else if (filterOverallData && filterOverallData.subtitle) {
        subtitle = filterOverallData.subtitle;
    } else if (categoryData && categoryData.ACFProductCategoryFields.subTitle) {
        subtitle = categoryData.ACFProductCategoryFields.subTitle;
    }

    return subtitle;
}

/**
 * Returns an Obj that merges the most relvant seo as follows: 
 * 
 *  Category > Filter specific title/desc take precedence, followed by
 *  Filter SEO and finally Category SEO.
 * 
 * @param {*} [{ filterData, filterSeo, categorySeo }={}]
 * @returns
 */
function getSeo({ filterData, filterSeo, categorySeo } = {}) {
    // Copy the SEO objects to avoid mutating the original
    
    // The base category SEO used as the base fallback
    const baseSeo = {...categorySeo};

    // The filter level SEO (applies to the overall filter rather than the specific category/filter)
    const filterLevelSeo = {...filterSeo};

    // Obj to compile specific category/filter details
    const filterCategorySeo = {};

    if (filterData && filterData.seoTitle && filterData.seoDescription) {
        filterCategorySeo.title = filterData.seoTitle;
        filterCategorySeo.metaDesc = filterData.seoDescription;
    } else if (filterData && filterData.seoTitle) {
        filterCategorySeo.title = filterData.seoTitle;
    } else if (filterData && filterData.seoDescription) {
        filterCategorySeo.metaDesc = filterData.seoDescription;
    }

    // Remove any null/empty string entries in the filter level SEO
    // This makes sure an empty string doesn't overwrite any present data at a lower level
    Object.keys(filterLevelSeo).forEach(key => {
        if (filterLevelSeo[key] === null || filterLevelSeo[key] === '') {
            delete filterLevelSeo[key];
        }
    });

    // Return the merged SEO objects
    return Object.assign({}, baseSeo, filterLevelSeo, filterCategorySeo);
}

/**
 * If on a filter page (i.e. class or level) returns the products that match that filter.  
 * Currently we can limit the products by category but not by category and additional
 * taxmony within the GraphQL so we recieve all products within the category.
 *
 * @param {*} { products, pageContext }
 * @returns
 */
function getFilteredProducts({ products, pageContext }) {
    let filteredProducts = products;

    // On a filter drill
    if (pageContext.filterId) {
        // Limit the category products to products that match the filter
        filteredProducts = products.filter(product => {
            const hasFilter = product[pageContext.filterType].nodes.filter(filter => filter.id === pageContext.filterId);
            
            return hasFilter.length;
        });
    }

    return filteredProducts;
}

/**
 * Filter data (for example title, subtitle, SEO) is retrieved from GraphQL 
 * for all categories as we cannot filter at this level within the query. 
 * 
 * This function finds any category data within the graphQL return and 
 * returns it filtered by the current category.  
 *
 * @param {*} [{ filterType, categoryId, data }={}]
 * @returns
 */
function getFilterDataByCategory({ filterType, categoryId, acfBlockType, data } = {}) {   
    // Checks if there is data specific to the filter type
    if (data[filterType]) {
        if(typeof data[filterType].nodes[0][acfBlockType] !== "undefined"){
            const filterData = data[filterType].nodes[0][acfBlockType].categoryFilterData;

            // Check if this data is relevant to the category
            if (filterData) {
                // Filters to the specific data
                const filteredData = filterData.filter(filterCategory => filterCategory.category.id === categoryId);

                 // Return the relevant data
                if (filteredData.length) {
                    return filteredData[0];
                }
            }     
        }   
    }

    return null;
}

const ProductsTemplate = ({ data, pageContext }) => {  
    // Current category and related data
    const categoryData = data.wordpress.productCategories.nodes[0];    
    const acfBlockType = pageContext.filterType === 'classes' ? 'ACFClassBlockFields' : 'ACFLevelBlockFields';
    const filterData = getFilterDataByCategory({ 
        filterType: pageContext.filterType,
        data: data.wordpress,
        categoryId: pageContext.id,
        acfBlockType
    });
    const hasFilterData = data.wordpress[pageContext.filterType];   
    const filterOverallData = hasFilterData ? data.wordpress[pageContext.filterType].nodes[0][acfBlockType] : null;
    const filterSeo = hasFilterData ? data.wordpress[pageContext.filterType].nodes[0].seo : null;

    // Products for the current category / filtered products to display
    const products = categoryData.products.nodes;
    const filteredProducts = getFilteredProducts({ products, pageContext });    
    
    // Categories data - Used to populate the categories menu and page content
    const allLanguageCategories = useCategoriesData();

    // Get data to populate the page
    const title = getTitle({ 
        name: pageContext.name,
        filterName: pageContext.filterName,
        filterData,
    });
    const description = getDescription({
        filterData,
        filterOverallData,
        categoryData,
    });
    const subtitle = getSubtitle({
        filterData,
        filterOverallData,
        categoryData,
    });
    const video = categoryData.ACFProductCategoryFields;
    const seo = getSeo({
        categorySeo: categoryData.seo,
        filterSeo,
        filterData,
    });
    const heroImage = categoryData && categoryData.ACFProductCategoryFields.headerImage ? categoryData.ACFProductCategoryFields.headerImage.imageFile : data.file;

    const { language, translations, slug } = categoryData;


    // find categories relevant to this page's language:
    let categories = [];
    let pageContextLanguageCode = "EN";
    if (typeof pageContext.language !== "undefined") {
        pageContextLanguageCode = pageContext.language.code;
    }
    let thisCategory, thisCategoryLanguage;
    for(thisCategory in allLanguageCategories) {
        thisCategoryLanguage = "EN";
        if(typeof allLanguageCategories[thisCategory].language !== "undefined") {
            thisCategoryLanguage = allLanguageCategories[thisCategory].language.code;
        }
        if(pageContextLanguageCode == thisCategoryLanguage){
            categories.push(allLanguageCategories[thisCategory]);
        }
    }



    // Create struture for categories menu 
    const categoriesMenu = categories.map(category => ({
        name: category.ACFProductCategoryFields.shortTitle ? category.ACFProductCategoryFields.shortTitle : category.name,
        slug: (category.language.code == "EN" ? '' : category.language.code.toLowerCase()+'/')+category.slug,
    }));
    
    // Filters
    const levels = getSectionFilter({
        slug: pageContext.slug,
        products: products,
        type: 'levels',
    });
    const classes = getSectionFilter({
        slug: pageContext.slug,
        products: products,
        type: 'classes',
    });

    let pageLanguage = 'EN';
    if(typeof language !== "undefined") {
        pageLanguage = language.code;
    }

    const pageLanguageKey = pageLanguage.toLowerCase();

    return (
        <Layout  language={language} translations={translations}>
            <SEO title={title} seo={seo} translations={translations} slug={slug} language={language} />
            <HeroBanner title={title} subtitle={subtitle} image={heroImage} video={video} emphasis />
            <SectionNav title="View Categories" links={categoriesMenu} partiallyActive />

            {typeof resources[pageLanguageKey] !== "undefined" ?
            <div className="container xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="row">
                    <div className={classes.length || levels.length ? "col-md-10 col-lg-7 offset-md-1 offset-lg-0" : "col"}>
                        <p className="u-text--small xs-pb-0" dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>

                    {classes.length || levels.length ?
                        <div className="col-lg-5">
                            <div className="row">
                                {classes.length ?
                                    <div className="col-6">
                                        <Dropdown
                                            identifier="level"
                                            title={resources[pageLanguageKey].translation.category["dropdown-level"]}
                                            label={resources[pageLanguageKey].translation.category["dropdown-see-all"]}
                                            active={pageContext.filterType === "levels" ? pageContext.filterName : null}
                                            list={levels}
                                        />
                                    </div>
                                : null}

                                {levels.length ?
                                    <div className="col-6">
                                        <Dropdown
                                            identifier="class"
                                            title={resources[pageLanguageKey].translation.category["dropdown-class"]}
                                            label={resources[pageLanguageKey].translation.category["dropdown-see-all"]}
                                            active={pageContext.filterType === "classes" ? pageContext.filterName : null}
                                            list={classes}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    : null}
                </div>
            </div>
            : null}

            {typeof resources[pageLanguageKey] !== "undefined" ?
            <div className="u-bg--wild-sand xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="container">
                    <ProductsList products={filteredProducts} />
                    <CTABanner linkLabel={resources[pageLanguageKey].translation.category["download-guide"]} />
                </div>
            </div>
            : null}

            <Info language={pageLanguage} />
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query ($slug: [String], $filterSlug: [String]) {
        file(relativePath: {eq: "default-banner-plp.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        wordpress {
            classes(first: 10000, where: {slug: $filterSlug}) {
                nodes {
                    ACFClassBlockFields {
                        subtitle
                        description
                        categoryFilterData {
                            category {
                                ... on WORDPRESS_ProductCategory {
                                    id
                                    name
                                }
                            }
                            description
                            seoDescription
                            seoTitle
                            subtitle
                            title
                        }
                    }
                    seo {
                        ...SeoFields
                    }
                }
            }
            levels(first: 10000, where: {slug: $filterSlug}) {
                nodes {
                    ACFLevelBlockFields {
                        subtitle
                        description
                        categoryFilterData {
                            category {
                                ... on WORDPRESS_ProductCategory {
                                    id
                                    name
                                }
                            }
                            description
                            seoDescription
                            seoTitle
                            subtitle
                            title
                        }
                    }
                    seo {
                        ...SeoFields
                    }
                }
            }
            productCategories(first: 10000, where: {slug: $slug}) {
                nodes {
                    name
                    slug
                    description
                    seo {
                        ...SeoFields
                    }
                    language {
                        code
                        locale
                    }
                    translations {
                        # id
                        uri
                        language {
                            code
                            locale
                        }
                    }
                    ACFProductCategoryFields {
                        shortTitle
                        subTitle
                        videoMp4 {
                            mediaItemUrl
                        }
                        videoWebm {
                            mediaItemUrl
                        }
                        headerImage {
                            sourceUrl
                            altText
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    products(first: 10000, where: {status: PUBLISH}) {
                        nodes {
                            ACFProductBlockFields {
                                productType
                                classesInformation {
                                    class {
                                        ... on WORDPRESS_Class {
                                            name
                                        }
                                    }
                                }
                                brandLogo {
                                    altText
                                    sourceUrl
                                    imageFile {
                                        childImageSharp {
                                            fixed(width: 40) {
                                                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                            }
                                        }
                                    }
                                }
                                whoShouldBuyIt
                                price
                                skill
                                shortDescription
                                descriptionExcerpt
                                bestSeller
                                racesWon {
                                    race
                                }
                                mainImage {
                                    altText
                                    sourceUrl
                                    imageFile {
                                        childImageSharp {
                                            fluid(maxWidth: 1580) {
                                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                                presentationWidth
                                            }
                                        }
                                    }
                                }
                            }
                            classes {
                                nodes {
                                    name
                                    id
                                    slug
                                }
                            }
                            levels {
                                nodes {
                                    id
                                    name
                                    slug
                                }
                            }
                            slug
                            uri
                            title
                        }
                    }
                }
            }
        }
    }
`;

export default ProductsTemplate;
